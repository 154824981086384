export const TIME_SELECT_OPTION = [
    {
        label: 'Sáng',
        live_time: 1,
        ca: 1,
        options: [


            {
                value: '09:00',
                label: '09:00',
                live_time: 0,
            },
            {
                value: '09:45',
                label: '09:45',
                live_time: 0,
            },
            {
                value: '10:30',
                label: '10:30',
                live_time: 0,
            },
            {
                value: '11:15',
                label: '11:15',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Chiều',
        live_time: 2,
        ca: 2,
        options: [
            {
                value: '14:30',
                label: '14:30',
                live_time: 0,
            },
            {
                value: '15:15',
                label: '15:15',
                live_time: 0,
            },
            {
                value: '16:00',
                label: '16:00',
                live_time: 0,
            },
            {
                value: '16:45',
                label: '16:45',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Tối',
        live_time: 3,
        ca: 3,
        options: [
            {
                value: '18:00',
                label: '18:00',
                live_time: 0,
            },
            {
                value: '18:45',
                label: '18:45',
                live_time: 0,
            },
            {
                value: '19:30',
                label: '19:30',
                live_time: 0,
            },
            {
                value: '20:15',
                label: '20:15',
                live_time: 0,
            }
        ]
    },
]

export const TIME_SELECT_OPTION_ONLINE = [
    {
        label: 'Sáng',
        live_time: 1,
        ca: 1,
        options: [

            {
                value: '08:15',
                label: '08:15',
                live_time: 0,
            },
            {
                value: '09:00',
                label: '09:00',
                live_time: 0,
            },
            {
                value: '09:45',
                label: '09:45',
                live_time: 0,
            },
            {
                value: '10:30',
                label: '10:30',
                live_time: 0,
            },
            {
                value: '11:15',
                label: '11:15',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Chiều',
        live_time: 2,
        ca: 2,
        options: [
            {
                value: '13:45',
                label: '13:45',
                live_time: 0,
            },
            {
                value: '14:30',
                label: '14:30',
                live_time: 0,
            },
            {
                value: '15:15',
                label: '15:15',
                live_time: 0,
            },
            {
                value: '16:00',
                label: '16:00',
                live_time: 0,
            },
            {
                value: '16:45',
                label: '16:45',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Tối',
        live_time: 3,
        ca: 3,
        options: [
            {
                value: '18:00',
                label: '18:00',
                live_time: 0,
            },
            {
                value: '18:45',
                label: '18:45',
                live_time: 0,
            },
            {
                value: '19:30',
                label: '19:30',
                live_time: 0,
            },
            {
                value: '20:15',
                label: '20:15',
                live_time: 0,
            },
            {
                value: '21:00',
                label: '21:00',
                live_time: 0,
            }
        ]
    },
]