<script>
export default {
  name: "CancelCreateInput",
  methods:{
    closeModal(){
      this.$emit('closeModal')
    }
  }
}
</script>

<template>
  <div class="text-center">
  <img src="../../../../../public/media/cancelTestInput.png" alt="">
  <h1 style="font-size: 16px;color: #FA5252;
" class="mt-2" >Không thành công</h1>
  <h1 class="mt-3" style="font-size: 13px;
">Khung giờ hiện tại đã hết slot, vui lòng đặt lại lịch khác.</h1>
  <button type="button" class="btn btn-secondary mt-3" @click="closeModal()" >Đóng</button>
  </div>
</template>

<style scoped>

</style>