<script>
export default {
  name: "OverloadCreateTestInput",
  methods:{
    closeModal(){
      this.$emit('closeModal')
    }
  }
}
</script>

<template>
  <div class="text-center">
    <img src="../../../../../public/media/overloadTestInput.png" alt="">
    <h1 style="font-size: 16px;color: #FA5252;
" class="mt-2" >Hệ thống đang quá tải.</h1>
    <h1 class="mt-3" style="font-size: 13px;
">Vui lòng đặt lịch lại sau ít phút.</h1>
    <button type="button" class="btn btn-secondary mt-3" @click="closeModal()">Đóng</button>
  </div>
</template>

<style scoped>

</style>