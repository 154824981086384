export const CA_LAM_VIEC = {
    1: "Ca sáng (8h30-12h00)",
    2: "Ca chiều (14h00-18h00)",
    3: "Ca tối (18h00-21h00)"
}

export const CA_LAM_VIEC_KEY_TEXT = {
    morning: "Ca sáng (8h-11h30)",
    afternoon: "Ca chiều (14h-17h30)",
    evening: "Ca tối (18h00-20h30)"
}

export const ALL = 0;
export const ONLINE = 1;
export const OFFLINE = 2;
export const TYPES = [
    {
        id: 1,
        name: "Online",
    },
    {
        id: 2,
        name: "Offline"
    }
];

export const TYPE_TASK = [
    {
        id: 1,
        name: "Test đầu vào",
    },
    {
        id: 2,
        name: "Gia sư",
    },
    {
        id: 3,
        name: "Coi thi"
    },
    {
        id: 4,
        name: "Tác vụ khác"
    }
];
export const GIA_SU = 2;
export const COI_THI = 3;
export const TAC_VU_KHAC = 4;

export const TYPE_TASK_DEFINE = {
    1: 'Test đầu vào',
    2: 'Gia sư',
    3: 'Coi thi',
    4: 'Tác vụ khác'
};

export const TYPE_TASK_DEFINE_CSS = {
    1: 'badge badge-info',
    2: 'badge badge-success',
    3: 'badge badge-danger',
    4: 'badge badge-warning',
};

export const TYPE_TASK_TEST_INPUT = 1;
export const TYPE_TASK_TUTOR = 2;

export const WORKINGFORM = [
    {
        id: 0,
        name: "ALL"
    },
    {
        id: 1,
        name: "ONLINE"
    },
    {
        id: 2,
        name: "OFFLINE"
    },
]
export const IELTS = 0;
export const TYPE_EXAMS = [
    {
        id: 0,
        name: "Ielts"
    },
    {
        id: 1,
        name: "Toeic"
    },
    {
        id: 2,
        name: "Giao tiêp"
    },
]




export const STATUS = [
    {
        id: 0,
        name: "Đã đặt lịch"
    },
    {
        id: 1,
        name: "Đã test general"
    },
    {
        id: 2,
        name: "Đã test speaking"
    },
    {
        id: 3,
        name: "Chờ chấm điểm"
    },
    {
        id: 4,
        name: "Đã trả kết quả"
    },
    {
        id: 5,
        name: "Hủy lịch"
    }
];

export const STATUS_DEFINE = {
    0: 'Đã đặt lịch',
    1: 'Đã test general',
    2: 'Đã test speaking',
    3: 'Chờ chấm điểm',
    4: 'Đã trả kết quả',
    5: 'Hủy lịch',
};

export const STATUS_DEFINE_CSS = {
    0: 'badge badge-info',
    1: 'badge badge-success',
    2: 'badge badge-danger'
};

 export const STATUS_HIDDEN = 0;
export const STATUS_VISIBLE = 1;
export const GENERAL = 0;
export const LISTENING = 1;
export const READING = 2;
export const WRITING = 3;
export const SPEAKING = 4;

export const TYPE_TEST_BY_KEY = new Map([
    ['vocabulary', GENERAL],
    ['listening', LISTENING],
    ['reading', READING],
    ['writing', WRITING],
    ['speaking', SPEAKING]
])

//tiêu chí nhận xét speaking
export const COMMENT_FLUENCY_AND_COHERENCE = {
    0: '• Học viên không tham gia Test ',
    1: "• Học viên chưa thể giao tiếp và truyền đạt được ý tưởng\n" +
        "• Ngôn ngữ chưa thể đánh giá được",
    2: "• Khi trình bày, có các khoảng dừng dài trước hầu hết các từ\n" +
        "• Khả năng truyền đạt ý tưởng cần cải thiện thêm ",
    3: "• Khi học viên trình bày còn có những khoảng dừng dài\n" +
        "• Khả năng liên kết các câu đơn còn hạn chế\n" +
        "• Chỉ đưa ra được những câu trả lời đơn giản và thường không truyền tải được thông điệp cơ bản",
    4: "• Trong lúc trả lời vẫn có những khoảng dừng đáng chú ý, tốc độ nói của học viên khá chậm, thường xuyên bị lặp và tự sửa lỗi\n" +
        "• Học viên có thể liên kết được các câu cơ bản nhưng còn sử dụng lặp đi lặp lại các phép liên kết đơn giản, tính mạch lạc vẫn còn bị gián đoạn. ",
    5: "• Học viên thường xuyên duy trì được độ trôi chảy của lời nói nhưng phải lặp lại, tự sửa lỗi và nói chậm để có thể nói liên tục\n" +
        "• Học viên có thể lạm dụng (sử dụng quá mức) một số từ nối, phép nối và discourse markers*\n" +
        "• Tạo ra được những lời nói đơn giản và lưu loát, nhưng việc truyền đạt bị phức tạp hơn nên gây ra các vấn đề về độ trôi chảy",
    6: "• Học viên có thể mở rộng câu trả lời, mặc dù đôi khi có thể mất độ mạch lạc do thỉnh thoảng lặp lại, tự sửa lỗi hoặc do ngập ngừng\n" +
        "• Sử dụng nhiều các phép nối và discourse markers nhưng không phải lúc nào cũng thích hợp",
    7: "• Học viên có thể mở rộng, kéo dài câu nói mà không cần nỗ lực nhiều hoặc mất tính mạch lạc\n" +
        "• Đôi khi có thể thể hiện sự ngập ngừng liên quan đến ngôn ngữ hoặc một số sự lặp lại và / hoặc tự điều chỉnh, sửa lỗi\n" +
        "• Sử dụng nhiều, đa dạng và linh hoạt các phép nối nối cũng như discourse markers",
    8: "        \n" +
        "• Học viên duy trì bài nói một cách trôi chảy, chỉ thỉnh thoảng lặp từ hoặc tự sửa lỗi hay ngập ngừng do tìm nội dung, ý diễn đạt chứ hiếm khi phải dừng để tìm từ ngữ\n" +
        "• Phát triển các chủ đề một cách mạch lạc và phù hợp",
    9: "• Học viên duy trì bài nói một cách trôi chảy và hiếm khi lặp lại hay tự điều chỉnh, sửa lỗi\n" +
        "• Mọi sự do dự, ngập ngừng trong lúc nói đều liên quan đến nội dung hơn là tìm từ hoặc ngữ pháp\n" +
        "• Nói mạch lạc sử dụng các đặc trưng liên kết một cách hoàn toàn thích hợp\n" +
        "• Phát triển các chủ đề một cách đầy đủ và hợp lý",
}
export const COMMENT_LEXICAL_RESOURCE = {
    0: '• Học viên không tham gia Test ',
    1: "• Học viên chưa thể giao tiếp và truyền đạt được ý tưởng",
    2: "•  Học viên chỉ nói được những từ đơn, riêng biệt hoặc những câu nói được ghi nhớ",
    3: "• Chỉ sử dụng được những từ vựng đơn giản để truyền đạt thông tin cá nhân\n" +
        "•  Thiếu vốn từ để diễn đạt nhưng chủ đề ít quen thuộc hơn",
    4: "•  Có thể nói về các chủ đề quen thuộc. Tuy nhiên chỉ có thể truyền đạt ý nghĩa cơ bản về các chủ đề không quen thuộc và thường xuyên mắc lỗi trong việc lựa chọn từ ngữ\n" +
        "• Hiếm khi cố gắng thay đổi cách diễn đạt (paraphrase)",
    5: "• Có thể nói được về cả các chủ đề quen thuộc và không quen thuộc nhưng sử dụng từ vựng còn ít linh hoạt\n" +
        "• Có cố gắng sử dụng nhiều cách để diễn đạt nhưng thường không thành công",
    6: "• Có vốn từ vựng đủ rộng để có những cuộc thảo luận dài về nhiều chủ đề và làm cho ý nghĩa rõ ràng mặc dù còn những chỗ không phù hợp\n" +
        "• Nhìn chung diễn đạt ý được bằng nhiều cách chính xác",
    7: "• Sử dụng nguồn từ vựng một cách linh hoạt để thảo luận về nhiều chủ đề khác nhau\n" +
        "• Sử dụng được một số thành ngữ và các từ vựng ít phổ biến hơn, đồng thời cho thấy một số kiến thức về văn phong và cụm từ, tuy nhiên các sự lựa chọn chưa được phù hợp\n" +
        "• Sử dụng hiệu quả nhiều cách diễn đạt (paraphrase) khác nhau",
    8: "• Sử dụng nguồn từ vựng phong phú và linh hoạt để truyền đạt ý nghĩa một cách chính xác\n" +
        "• Sử dụng các từ vựng ít phổ biến và thành ngữ một cách khéo léo, chỉ đôi khi không chính xác\n" +
        "• Sử dụng nhiều cách diễn đạt hiệu quả như được yêu cầu",
    9: "• Sử dụng từ vựng một cách linh hoạt và chính xác trong tất cả các chủ đề\n" +
        "• Sử dụng các thành ngữ một cách tự nhiên và chính xác",
}
export const COMMENT_GRAMMATICAL_RANGE_ACCURACY = {
    0: '• Học viên không tham gia Test ',
    1: "• Không có sự giao tiếp",
    2: "• Không thể hình thành các dạng câu cơ bản",
    3: "• Cố gắng sử dụng các dạng câu cơ bản nhưng ít khi thành công hoặc dựa vào các câu nói có vẻ đã thuộc lòng\n" +
        "• Mắc nhiều lỗi ngoại trừ các cách diễn đạt đã được ghi nhớ",
    4: "• Hình thành được các dạng câu cơ bản và một số câu đơn giản đúng nhưng hiếm khi sử dụng các dạng cấu trúc câu phức\n" +
        "• Thường xuyên mắc lỗi và các lỗi có thể dẫn đến sự khó hiểu hoặc hiểu nhầm",
    5: "• Sử dụng các dạng câu cơ bản một cách hợp lý và chính xác\n" +
        "• Có sử dụng một số ít các cấu trúc phức tạp hơn, nhưng những cấu trúc này thường có lỗi và có thể gây ra một số vấn đề về việc hiểu",
    6: "• Sử dụng kết hợp các cấu trúc đơn giản và phức tạp nhưng ít linh hoạt\n" +
        "• Có thể vẫn mắc lỗi thường xuyên với các cấu trúc phức tạp nhưng những lỗi này hiếm khi gây ra các vấn đề về hiểu",
    7: "• Sử dụng nhiều cấu trúc phức tạp một cách khá linh hoạt\n" +
        "• Các câu được tạo ra thường là không có lỗi, hoặc chỉ tồn tại một số lỗi ngữ pháp",
    8: "• Sử dụng nhiều và đa dạng các loại cấu trúc một cách linh hoạt\n" +
        "• Phần lớn các câu không có lỗi, chỉ thỉnh thoảng không phù hợp hoặc mắc các lỗi cơ bản/ lỗi không hệ thống",
    9: "• Sử dụng đầy đủ các cấu trúc một cách tự nhiên và thích hợp\n" +
        "• Cấu trúc các câu chính xác và nhất quán, loại trừ các “lỗi nhỏ”’ trong đặc điểm cách nói của người bản ngữ",
}
export const COMMENT_PRONUNCIATION = {
    0: "• Học viên không tham gia Test ",
    1: "• Không có sự giao tiếp",
    2: "• Lời nói thường không thể hiểu được",
    3: "• Thể hiện một số đặc điểm của Band 2 và một số, nhưng không phải tất cả các tính năng tích cực của Band 4",
    4: "• Sử dụng được một số đặc điểm ngữ âm nhưng còn hạn chế\n" +
        "• Có nhiều đoạn ngừng có thể chấp nhận được, tuy nhiên ngữ điệu tổng thể vẫn còn thiếu sót\n" +
        "• Cố gắng sử dụng các yếu tố ngữ điệu và trọng âm, tuy nhiên việc kiểm soát còn hạn chế\n" +
        "• Các từ và âm tiết thường phát âm sai, gây khó hiểu.\n" +
        "• Người nghe cần cố gắng để hiểu nội dung, một vài phần trong câu nói gây khó hiểu\"",
    5: "• Thể hiện được tất cả các tính năng tích cực của Band 4 và một số, nhưng không phải tất cả các tính năng tích cực của Band 6",
    6: "• Sử dụng được một số các thành tố phát âm nhưng việc kiểm soát còn lẫn lộn\n" +
        "• Cho thấy khả năng sử dụng hiệu quả một số thành tố nhưng điều này không được duy trì\n" +
        "• Nhìn chung bài nói có thể được hiểu xuyên suốt, mặc dù việc phát âm sai các từ hoặc âm đôi khi làm giảm độ rõ ràng",
    7: "• Thể hiện tất cả các tính năng tích cực của Band 6 và một số, nhưng không phải tất cả các tính năng tích cực của Band 8",
    8: "• Sử dụng nhiều và đa dạng các thành tố phát âm\n" +
        "• Duy trì được việc sử dụng linh hoạt các thành tố này, chỉ thỉnh thoảng mắc lỗi\n" +
        "• Xuyên suốt bài nói dễ hiểu; giọng ngôn ngữ thứ nhất (tiếng Việt) ảnh hưởng rất ít đến khả năng hiểu",
    9: "• Sử dụng đầy đủ các thành tố phát âm với độ chính xác và sự tinh tế\n " +
        "• Duy trì việc sử dụng linh hoạt các thành tố này xuyên suốt bài nói\n" +
        "• Có thể dễ dàng hiểu mà không cần nỗ lực"
}

// tiêu chí nhạn xét writing
export const COMMENT_TASK_ACHIEVEMENT_WRITING = {
    0: "• Học viên không hoàn thành bài Test \n" +
        "• Học viên không tham gia phần thi Writing \n" +
        "• Bài làm có tỉ lệ đạo văn 100%",
    1: "• Bài viết hoàn toàn không liên quan đến đề bài",
    2: "• Bài viết hầu như không đáp ứng được yêu cầu đề bài \n" +
        "• Bài viết chưa bày tỏ được quan điểm\n" +
        "• Có thể cố gắng trình bày một hoặc hai ý tưởng nhưng chưa có sự phát triển ý ",
    3: "• Bài viết chưa đáp ứng được yêu cầu của đề bài \n" +
        "• Chưa bày tỏ quan điểm một cách rõ ràng\n" +
        "• Trình bày được một số ý tưởng, phần lớn chưa được phát triển hoặc không liên quan tới đề bài",
    4: "• Bài viết chỉ đáp ứng một phần rất nhỏ yêu cầu đề bài  hoặc câu trả lời là tiếp tuyến; cấu trúc bài có thể chưa phù hợp\n" +
        "• Đã có trình bày một quan điểm nhưng chưa thật sự rõ ràng\n" +
        "• Trình bày một số ý chính nhưng khó xác định và có thể lặp lại ý, không liên quan hoặc các supporting ideas chưa tốt. ",
    5: "• Bài viết chỉ đáp ứng một phần yêu cầu đề bài; cấu trúc có thể không phù hợp ở một số phần\n" +
        "• Bài viết đã thể hiện được quan điểm nhưng một số ý còn chưa rõ ràng và có thể không rút ra được kết luận cụ thể nào\n" +
        "• Trình bày được một số ý chính nhưng còn hạn chế và chưa phát triển đầy đủ; có thể có một vài chi tiết không liên quan",
    6: "• Bài viết đáp ứng được tất cả các yêu cầu của đề bài mặc dù các phần chưa được bao quát tương đương nhau \n" +
        "• Trình bày quan điểm có liên quan mặc dù kết luận có thể trở chưa rõ ràng hoặc lặp đi lặp lại\n" +
        "• Trình bày những ý chính có liên quan nhưng một số ý có thể chưa được phát triển đầy đủ/không rõ ràng",
    7: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày quan điểm rõ ràng trong suốt bài làm\n" +
        "• Trình bày, mở rộng và hỗ trợ tốt cho các ý chính, nhưng có thể có xu hướng khái quát hóa quá mức và/hoặc các supporting ideas còn lan man, thiếu trọng tâm",
    8: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày bài làm được phát triển tốt cho câu hỏi với các ý tưởng có liên quan, mở rộng và các supporting ideas tốt",
    9: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày bài làm rất tốt, phát triển đầy đủ ý để trả lời cho yêu cầu với các ý tưởng phù hợp, mở rộng đầy đủ và các supporting ideas tốt"
};
export const COMMENT_LEXICAL_RESOURCE_WRITING = {
    0: "• Học viên không tham gia phần thi Writing",
    1: "•Bài viết chỉ có thể sử dụng một vài từ riêng lẻ",
    2: "• Sử dụng một lượng từ vựng hạn chế; về cơ bản không kiểm soát được các lỗi sai về cấu tạo từ và/hoặc chính tả ",
    3: "• Chỉ sử dụng một số lượng từ và cách diễn đạt còn hạn chế với khả năng kiểm soát các lỗi sai về cấu tạo từ và/hoặc chính tả rất hạn chế \n" +
        "• Các lỗi trong bài có thể làm sai nghĩa các ý ",
    4: "• Chỉ sử dụng từ vựng cơ bản,  có thể các từ được sử dụng lặp đi lặp lại hoặc có thể sử dụng từ không phù hợp.\n " +
        "• Kiểm soát các lỗi sai về cấu tạo từ và/hoặc chính tả chưa tốt; lỗi có thể gây ra hiểu lầm cho người đọc",
    5: "• Sử dụng một lượng từ vựng hạn chế, nhưng điều này tối thiểu phù hợp với yêu cầu đề bài \n" +
        "• Bài viết mắc lỗi đáng chú ý về chính tả và/hoặc cấu tạo từ, có thể gây khó khăn cho người đọc",
    6: "• Bài viết sử dụng lượng từ vựng phù hợp theo yêu cầu đề bài\n" +
        "• Học viên đã cố gắng sử dụng từ vựng ít phổ biến hơn nhưng có một số chỗ, cách sử dụng từ chưa phù hợp \n" +
        "• Mắc một số lỗi chính tả và/hoặc cấu tạo từ, nhưng không làm sai nghĩa ",
    7: "• Bài viết sử dụng lượng từ vựng phù hợp, linh động, chính xác theo yêu cầu đề bài\n" +
        "• Sử dụng các từ vựng ít phổ biến hơn, có biết về Collocation và Style. \n" +
        "• Thỉnh thoảng có thể mắc lỗi trong việc lựa chọn từ, chính tả và/hoặc cấu tạo từ",
    8: "• Sử dụng nhiều loại từ vựng một cách trôi chảy và linh hoạt để truyền đạt ý nghĩa chính xác\n" +
        "• khéo léo sử dụng các mục từ vựng không phổ biến. Tuy nhiên,  việc lựa chọn từ và sắp xếp thứ tự còn chưa chính xác. \n" +
        "• Mắc các lỗi hiếm gặp về chính tả và/hoặc cấu tạo từ",
    9: "• Bài viết sử dụng nhiều loại từ vựng với khả năng kiểm soát các đặc điểm của từ vựng rất tự nhiên và tinh vi; các lỗi nhỏ hiếm gặp chỉ xảy ra dưới dạng 'nhầm lẫn'"
};
export const COMMENT_GRAMMATICAL_RANGE_ACCURACY_WRITING = {
    0: "• Bài viết hoàn toàn không thể sử dụng các mẫu câu",
    1: "• Bài viết không thể sử dụng các mẫu câu ngoại trừ trong các cụm từ được ghi nhớ",
    2: "• Học viên đã cố gắng đặt câu nhưng bài viết còn tồn tại nhiều lỗi ngữ pháp và dấu câu chiếm và làm sai lệch ý nghĩa",
    3: "• Bài viết chỉ sử dụng một phạm vi cấu trúc rất hạn chế và hiếm khi sử dụng các mệnh đề phụ\n" +
        "• Một số cấu trúc chính xác nhưng đa số vẫn mắc lỗi và dấu câu thường không phù hợp",
    4: "• Bài viết chỉ sử dụng một số cấu trúc hạn chế\n" +
        "• Cố gắng đặt những câu phức tạp nhưng những câu này có xu hướng kém chính xác hơn những câu đơn giản\n" +
        "• Có thể mắc lỗi ngữ pháp thường xuyên và dấu câu có thể bị lỗi; lỗi có thể gây ra một số khó khăn cho người đọc",
    5: "",
    6: "• Bài viết sử dụng kết hợp các dạng câu đơn giản và phức tạp\n" +
        "• Mắc một số lỗi ngữ pháp và dấu câu nhưng hiếm khi làm ảnh hưởng tới ngữ nghĩa ",
    7: "• Bài viết sử dụng nhiều cấu trúc phức tạp\n" +
        "• Phần lớn các câu trong bài đều không mắc lỗi \n" +
        "• Kiểm soát ngữ pháp và dấu câu tốt nhưng có thể mắc một vài lỗi",
    8: "• Bài viết sử dụng đa dạng các cấu trúc câu \n" +
        "• Đa số các câu trong bài đều không mắc lỗi \n" +
        "• Mắc một số lỗi rất hiếm",
    9: "• Bài viết sử dụng đa dạng các cấu trúc với đầy đủ tính linh hoạt và chính xác; các lỗi nhỏ hiếm gặp chỉ xảy ra dưới dạng 'nhầm lẫn'"
};
export const COMMENT_COHERENCE_COHESION_WRITING = {
    1: "• Bài viết chưa truyền đạt được ý",
    2: "• Chưa kiểm soát được các tính năng của cấu trúc bài",
    3: "• Chưa trình bày được ideas một cách logic\n" +
        "• Học viên có thể sử dụng một số lượng rất hạn chế các Từ nối. Tuy nhiên các từ nối có thể chưa liên kết được mối quan hệ logic giữa các ideas.",
    4: "• Bài viết trình bày thông tin và ý tưởng nhưng chưa được sắp xếp một cách mạch lạc và chưa có sự tiến triển rõ ràng trong câu trả lời\n" +
        "• Sử dụng một số Từ nối cơ bản nhưng những từ nối này có thể không chính xác hoặc lặp đi lặp lại\n" +
        "• Có thể không viết thành đoạn văn hoặc chức năng đoạn có thể gây nhầm lẫn",
    5: "• Bài viết trình bày thông tin với một số cách sắp xếp ý tưởng nhưng có thể thiếu diễn tiến tổng thể\n" +
        "• Các từ nối chưa đầy đủ, chưa chính xác hoặc lạm dụng. \n" +
        "• Có thể lặp đi lặp lại vì thiếu tham chiếu và thay thế\n" +
        "• Có thể không viết thành đoạn văn, hoặc đoạn văn có thể không đầy đủ",
    6: "• Bài viết sắp xếp thông tin và ý tưởng một cách mạch lạc và có một diễn tiến tổng thể rõ ràng\n" +
        "• Sử dụng các Từ nối một cách hiệu quả, nhưng sự liên kết bên trong và/hoặc giữa các câu có thể bị lỗi hoặc máy móc hoá\n" +
        "• Có thể không phải lúc nào cũng sử dụng tham chiếu một cách rõ ràng hoặc phù hợp\n" +
        "• Bài viết có phân đoạn, nhưng không phải lúc nào cũng hợp lý",
    7: "• Bài viết sắp xếp thông tin và ý tưởng một cách hợp lý; có sự tiến bộ rõ ràng trong suốt bài \n" +
        "• Sử dụng một loạt các từ nối một cách thích hợp mặc dù có thể có một số từ bị sử dụng dưới mức/quá mức\n" +
        "• Trình bày một ý chính, trọng tâm rõ ràng trong mỗi đoạn văn",
    8: "• Bài viết trình bày các  thông tin và ý tưởng một cách hợp lý\n" +
        "• Có tính liên kết cao\n" +
        "• Phân đoạn đầy đủ và phù hợp",
    9: "• Bài viết có tính liên kết cao, rất tự nhiên \n" +
        "• Phân đoạn rất tốt, kĩ thuật tốt. "
};

export const NAME_CRITERIA = new Map([
    ["fluency_and_coherence", "Độ trôi chảy và mạch lạc"],
    ["lexical_resource", "Vốn từ"],
    ["grammatical_range_accuracy", "Độ đa dạng và chính xác của ngữ pháp"],
    ["pronunciation", "Phát âm"],
    ["task_achievement_writing", "Đáp ứng yêu cầu đề bài"],
    ["lexical_resource_writing", "Ngữ pháp"],
    ["grammatical_range_accuracy_writing", "Từ vựng"],
    ["coherence_cohesion_writing", "Độ mạch lạc"]

])
export const TYPE_TEST = new Map([
    [GENERAL, 'Vocabulary & Grammar'],
    [LISTENING, 'Listening'],
    [READING, 'Reading'],
    [WRITING, 'Writing'],
    [SPEAKING, 'Speaking']
])

export const EXPAND_AND_DEVELOPIDEAS = {
    vName: 'Mở rộng và phát triển ý',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng mở rộng ý tưởng, thường sử dụng ngôn ngữ cơ bản và không thể diễn đạt ý kiến một cách rõ ràng hoặc logic.",
        4: "Học viên có khả năng mở rộng ý tưởng rất hạn chế và không có sự phát triển rõ ràng. Họ sử dụng ngôn ngữ cơ bản và không thể diễn đạt ý kiến một cách chính xác. ",
        5: "Học viên có khả năng mở rộng ý tưởng hạn chế và không có sự phát triển rõ ràng. Họ có thể sử dụng ngôn ngữ cơ bản và không biểu đạt ý kiến một cách chính xác",
        6: "Học viên có khả năng mở rộng ý tưởng nhưng không liên kết chặt chẽ, thường lặp lại ý tưởng hoặc thông tin. Họ sử dụng ngôn ngữ một cách cơ bản và không sáng tạo, có thể gặp khó khăn trong việc diễn đạt ý kiến.",
        7: "Học viên có khả năng mở rộng ý tưởng khá tốt, nhưng có thể thiếu sự linh hoạt trong việc sử dụng ngôn ngữ. Họ có thể diễn đạt ý kiến một cách rõ ràng, nhưng không sử dụng một loạt cấu trúc ngữ pháp và từ vựng đa dạng.",
        8: "Học viên có khả năng phát triển ý tưởng tốt và linh hoạt, dùng ngôn ngữ một cách chính xác và sáng tạo. Họ biểu đạt ý kiến một cách rõ ràng và có thể sử dụng một số cấu trúc ngữ pháp và từ vựng phức tạp.",
        9: "Học viên có khả năng mở rộng ý tưởng một cách tự nhiên và sâu sắc. Họ sử dụng một loạt các cấu trúc ngữ pháp và từ vựng phong phú, linh hoạt để trình bày ý kiến, và có khả năng diễn đạt ý tưởng phức tạp một cách rõ ràng và logic.",
    }
};
export const PAUSE_AND_HESITATE = {
    vName: 'Ngắt nghỉ và ngập ngừng',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên gặp rất nhiều khó khăn trong việc duy trì luồng nói liền mạch, thường có nhiều ngắt nghỉ và ngập ngừng, khiến bài nói trở nên rất khó hiểu và mất tính chính xác",
        4: "Học viên có khả năng duy trì luồng nói rất hạn chế, thường xuyên gặp ngắt nghỉ và ngập ngừng lớn, làm mất đi tính mạch lạc và dễ hiểu của bài nói.",
        5: "Học viên có khả năng duy trì luồng nói hạn chế và thường xuyên gặp ngắt nghỉ và ngập ngừng. Sự gián đoạn này có thể làm mất đi sự rõ ràng và dễ hiểu của bài nói.",
        6: "Học viên có khả năng duy trì luồng nói ở mức trung bình. Họ có thể gặp một số ngắt nghỉ và ngập ngừng trong khi nói, điều này có thể làm mất đi sự mạch lạc của bài nói của họ.",
        7: "Học viên có khả năng duy trì luồng nói tương đối liền mạch, mặc dù đôi khi có thể có một số ngắt nghỉ và ngập ngừng. Sự ngắt nghỉ này không gây ra sự rối loạn lớn trong luồng ý tưởng.",
        8: "Học viên có khả năng nói liền mạch một cách khá tự nhiên, mặc dù có thể có một số ngắt nghỉ nhỏ hoặc ngập ngừng không đáng kể. Tuy nhiên, điều này không ảnh hưởng nhiều đến khả năng của họ trong việc truyền đạt ý kiến.",
        9: "Học viên có khả năng nói liền mạch một cách tự nhiên và mượt mà, không có ngắt nghỉ hoặc ngập ngừng đáng kể, nếu có thì đều có chủ đích. Họ duy trì luồng nói một cách mạch lạc và dễ dàng theo dõi, thể hiện sự chính xác và tự tin trong diễn đạt.",
    }
};
export const SPEAKING_SPEED = {
    vName: 'Tốc độ nói',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng duy trì tốc độ nói ổn định, thường có sự thay đổi đột ngột và không đều, khiến bài nói trở nên rất khó hiểu và mất tính chính xác.",
        4: "Học viên có khả năng duy trì tốc độ nói rất hạn chế, thường có sự thay đổi đột ngột trong tốc độ nói, làm mất đi tính liên tục và dễ hiểu của bài nói.",
        5: "Học viên có khả năng duy trì tốc độ nói hạn chế và thường xuyên có những thay đổi đột ngột, làm mất đi tính liên tục và sự dễ hiểu của bài nói.",
        6: "Học viên có khả năng duy trì tốc độ nói ở mức trung bình. Họ có thể có sự thay đổi đột ngột trong tốc độ nói, làm mất đi tính liên tục và mạch lạc của bài nói.",
        7: "Học viên có khả năng duy trì tốc độ nói tương đối ổn định. Tuy nhiên, có thể có một số chỗ ngắt quãng hoặc chậm lại, điều này có thể làm mất đi sự mạch lạc trong bài nói.",
        8: "Học viên có khả năng duy trì tốc độ nói khá tốt. Họ điều chỉnh tốc độ để phù hợp với nội dung và người nghe, mặc dù có thể có một số chỗ ngắt quãng hoặc tăng tốc độ không đều.",
        9: "Học viên có khả năng duy trì tốc độ nói lý tưởng, phù hợp với bài nói và người nghe. Họ điều chỉnh tốc độ nói một cách linh hoạt để phù hợp với các ý tưởng phức tạp và biểu đạt một cách rõ ràng và tự tin.",
    }
};
export const USEPHRASES_TO_OPEN_LEAD_AND_CONNECT = {
    vName: 'Sử dụng các cụm từ để mở đầu, dẫn dắt, kết nối',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng sử dụng các cụm từ mở đầu và kết nối, dẫn đến bài nói trở nên rất khó hiểu và không có mạch lạc.",
        4: "Học viên sử dụng các cụm từ mở đầu và kết nối rất hạn chế. Họ có thể thiếu cơ bản các cấu trúc cụm từ để mở đầu và kết nối ý tưởng, dẫn đến bài nói trở nên rời rạc và khó hiểu.",
        5: "Học viên có khả năng sử dụng các cụm từ mở đầu và kết nối hạn chế. Họ sử dụng ít cấu trúc cụm từ để mở đầu và kết nối ý tưởng, dẫn đến sự gián đoạn và thiếu mạch lạc trong bài nói của họ.",
        6: "Học viên sử dụng các cụm từ mở đầu và kết nối ở mức trung bình. Họ có thể sử dụng một số cấu trúc cụm từ để mở đầu và kết nối ý tưởng, nhưng thường có sự lặp lại và thiếu đồng nhất trong việc sử dụng chúng.",
        7: "Học viên có khả năng sử dụng các cụm từ mở đầu và kết nối tương đối tốt. Họ sử dụng một số cấu trúc cụm từ để mở đầu và liên kết ý tưởng trong bài nói, mặc dù có thể có sự thiếu đồng nhất và linh hoạt trong việc sử dụng chúng.",
        8: "Học viên sử dụng các cụm từ mở đầu và kết nối một cách linh hoạt và khéo léo. Họ có khả năng sử dụng một số cấu trúc cụm từ để mở đầu, dẫn dắt ý tưởng và kết nối các phần trong bài nói một cách hợp lý, tuy nhiên không đến mức hoàn hảo.",
        9: "Học viên sử dụng các cụm từ mở đầu và kết nối một cách tự nhiên và linh hoạt. Họ có khả năng sử dụng một loạt các cấu trúc cụm từ phong phú và đa dạng để mở đầu bài nói, dẫn dắt ý tưởng và liên kết các phần trong bài nói một cách mạch lạc và hợp lý.",
    }
};
export const COHERENCE = {
    vName: 'Độ mạch lạc',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng diễn đạt mạch lạc, có nhiều gián đoạn và thiếu liên kết, dẫn đến việc bài nói trở nên rất khó hiểu.",
        4: "Học viên có khả năng diễn đạt mạch lạc rất hạn chế, thường có nhiều gián đoạn và thiếu liên kết rõ ràng giữa các ý tưởng.",
        5: "Học viên có khả năng diễn đạt mạch lạc hạn chế và không có sự liên kết rõ ràng giữa các ý tưởng. Bài nói của họ có thể bị gián đoạn và khó hiểu.",
        6: "Học viên có khả năng diễn đạt mạch lạc ở mức trung bình. Họ có thể có nhiều gián đoạn hoặc sự thiếu liên kết giữa các ý tưởng, dẫn đến sự lặp lại hoặc thiếu logic trong bài nói của họ.",
        7: "Học viên có khả năng diễn đạt mạch lạc nhưng có thể có một số gián đoạn hoặc sự thiếu liên kết giữa các ý tưởng. Họ có khả năng sử dụng liên kết nhưng không liên tục.",
        8: "Học viên có khả năng diễn đạt mạch lạc và logic. Họ sử dụng các liên kết rõ ràng giữa các ý tưởng và thông tin, mặc dù có thể có một số gián đoạn nhỏ hoặc sự lặp lại trong việc diễn đạt.",
        9: "Học viên có khả năng diễn đạt mạch lạc, logic và mượt mà. Họ có khả năng sử dụng các liên kết logic giữa các ý tưởng, câu và đoạn văn một cách xuất sắc, tạo ra một bài nói tự nhiên và dễ hiểu.",
    }
};
// LEXICAL RESOURCE
export const WORD_DIVERSITY = {
    vName: 'Độ đa dạng của từ vựng',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng sử dụng từ vựng cơ bản, gây ra sự không rõ ràng và không hiểu rõ ý kiến của họ. Sử dụng từ ngữ không chính xác và không phù hợp là phổ biến.",
        4: "Học viên có khả năng sử dụng từ vựng rất hạn chế và cơ bản. Họ có thể sử dụng từ ngữ không chính xác hoặc không phù hợp, gây ra sự không rõ ràng và không chính xác trong việc diễn đạt ý kiến.",
        5: "Học viên có khả năng sử dụng từ vựng hạn chế và cơ bản. Họ sử dụng từ ngữ cơ bản để trình bày ý kiến, có thể gặp khó khăn trong việc biểu đạt ý tưởng phức tạp.",
        6: "Học viên có khả năng sử dụng từ vựng ở mức trung bình. Họ sử dụng từ ngữ cơ bản và không đa dạng, có thể lặp lại từ ngữ hoặc sử dụng từ vựng không phù hợp trong một số trường hợp.",
        7: "Học viên có khả năng sử dụng từ vựng tương đối đa dạng và phong phú. Họ sử dụng từ ngữ chính xác để diễn đạt ý kiến, tuy nhiên, có thể thiếu tính linh hoạt và sự sáng tạo trong việc sử dụng từ vựng.",
        8: "Học viên có khả năng sử dụng từ vựng khá đa dạng và linh hoạt. Họ sử dụng từ ngữ chính xác và phong phú để biểu đạt ý kiến, mặc dù có thể không như sáng tạo và phức tạp như ở band điểm 9.",
        9: "Học viên có khả năng sử dụng từ vựng rộng rãi và linh hoạt. Họ biết cách sử dụng từ ngữ chính xác, phức tạp và đa dạng để truyền đạt ý kiến một cách chính xác và sáng tạo.",
    }
};
export const ACCURACY_IN_USE_VOCABULARY = {
    vName: 'Độ chính xác trong sử dụng từ vựng',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường gặp rất nhiều khó khăn trong việc sử dụng từ vựng. Lỗi trong việc chọn từ hoặc sử dụng từ không phù hợp là phổ biến, và điều này gây hiểu lầm và không chính xác khi truyền đạt ý kiến.",
        4: "Học viên sử dụng từ vựng rất hạn chế và thường gặp nhiều lỗi trong việc chọn từ hoặc sử dụng từ không phù hợp, gây ra sự không rõ ràng và không chính xác trong diễn đạt.",
        5: "Học viên sử dụng từ vựng hạn chế và thường gặp nhiều lỗi trong việc chọn từ hoặc sử dụng từ không phù hợp với ngữ cảnh, gây hiểu lầm cho người nghe.",
        6: "Học viên sử dụng từ vựng ở mức trung bình. Họ sử dụng từ ngữ cơ bản và phù hợp để trình bày ý kiến, tuy nhiên có thể gặp một số lỗi hoặc sự lặp lại trong từ vựng.",
        7: "Học viên sử dụng từ vựng tương đối chính xác và phù hợp với ngữ cảnh. Họ có khả năng chọn từ ngữ tương đối chính xác để diễn đạt ý kiến, mặc dù có thể gặp một số hiểu lầm nhỏ hoặc sự lặp lại trong việc sử dụng từ vựng.",
        8: "Học viên sử dụng từ vựng khá chính xác và phong phú. Họ chọn từ ngữ phù hợp với ngữ cảnh và biểu đạt ý kiến một cách rõ ràng, ít gây hiểu lầm. Mặc dù có thể không như sáng tạo như ở band điểm cao hơn, nhưng vẫn đạt được mức độ linh hoạt.",
        9: "Học viên sử dụng từ vựng rất chính xác và phong phú. Họ chọn từ ngữ phù hợp với ngữ cảnh và biểu đạt ý kiến một cách chính xác, không gây hiểu lầm. Sự linh hoạt và sáng tạo trong việc sử dụng từ vựng cũng là điểm mạnh của họ.",
    }
};
export const PARAPHRASING = {
    vName: 'Paraphrasing',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên không có khả năng paraphrase. Sự tái diễn ý tưởng thường không chính xác hoặc không linh hoạt, dẫn đến sự hiểu lầm và không rõ ràng trong diễn đạt.",
        4: "Học viên có khả năng paraphrase rất hạn chế. Họ thường không thể tái diễn ý tưởng một cách chính xác hoặc linh hoạt, và có thể dẫn đến sự hiểu lầm.",
        5: "Học viên có khả năng paraphrase hạn chế. Họ có thể tái diễn ý tưởng nhưng thường thiếu sự linh hoạt và chính xác trong việc sử dụng từ ngữ, dẫn đến sự lặp lại.",
        6: "Học viên có khả năng paraphrase ở mức trung bình. Họ có thể tái diễn ý tưởng nhưng thường sử dụng từ vựng và cấu trúc câu gần giống nhau, không có nhiều sự linh hoạt.",
        7: "Học viên có khả năng paraphrase tương đối tốt. Họ có khả năng tái diễn ý tưởng với một mức độ chính xác và linh hoạt, dù có thể thiếu tính sáng tạo trong việc sử dụng từ ngữ.",
        8: "Học viên có khả năng paraphrase khá tốt. Họ thường tái diễn ý tưởng một cách chính xác và linh hoạt, dùng từ vựng và cấu trúc câu khác nhau để biểu đạt ý kiến một cách rõ ràng.",
        9: "Học viên có khả năng paraphrase rất tốt và linh hoạt. Họ biết cách tái diễn ý tưởng một cách sáng tạo và chính xác, sử dụng từ vựng và cấu trúc câu khác nhau một cách linh hoạt và tự nhiên.",
    }
};
// Paraphrasing
export const PRONUNCIATION_ABILITY = {
    vName: 'Khả năng phát âm',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường có khả năng phát âm kém. Lỗi phát âm xuất hiện thường xuyên và làm mất đi sự rõ ràng và hiểu rõ ý kiến của họ.",
        4: "Học viên có khả năng phát âm rất hạn chế. Họ gặp nhiều lỗi phát âm và có thể không dễ hiểu cho người nghe.",
        5: "Học viên có khả năng phát âm hạn chế. Họ thường gặp nhiều lỗi phát âm, làm mất đi tính rõ ràng và dễ hiểu của bài nói.",
        6: "Học viên có khả năng phát âm ở mức trung bình. Họ có thể phát âm các âm thanh và từ ngữ một cách tương đối rõ ràng, nhưng thường gặp nhiều lỗi phát âm nhỏ, có thể làm mất đi sự dễ hiểu.",
        7: "Học viên có khả năng phát âm tương đối chính xác. Họ có khả năng phát âm các âm thanh và từ ngữ với sự rõ ràng và dễ hiểu, mặc dù có thể gặp một số lỗi nhỏ hoặc có thể cần sự cải thiện để làm rõ hơn.",
        8: "Học viên có khả năng phát âm khá chính xác và tự nhiên. Họ thường phát âm các âm thanh và từ ngữ một cách rõ ràng và dễ hiểu, mặc dù có thể gặp một số lỗi nhỏ không ảnh hưởng nhiều đến sự hiểu rõ.",
        9: "Học viên có khả năng phát âm rất chính xác và tự nhiên. Họ phát âm các âm thanh, từ ngữ và câu văn một cách rõ ràng và dễ hiểu, không gặp lỗi phát âm nào đáng kể.",
    }
};
export const STRESS = {
    vName: 'Trọng âm',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường không có khả năng nhấn trọng âm. Sự không nhấn trọng âm đúng đắn gây ra sự mất tính rõ ràng và không hiểu rõ ý kiến của họ.",
        4: "Học viên có khả năng nhấn trọng âm cho các từ rất hạn chế. Họ thường không nhấn trọng âm đúng đắn, gây ra sự mất đi tính rõ ràng và dễ hiểu của bài nói.",
        5: "Học viên có khả năng nhấn trọng âm cho các từ hạn chế. Họ có thể không nhấn trọng âm đúng đắn cho một số từ, gây ra sự không rõ ràng và khó hiểu.",
        6: "Học viên có khả năng nhấn trọng âm cho các từ ở mức trung bình. Họ có thể nhấn trọng âm cho các từ một cách tương đối đúng, nhưng có thể gặp khó khăn trong việc duy trì tính chính xác và nhất quán.",
        7: "Học viên có khả năng nhấn trọng âm cho các từ tương đối chính xác. Họ thường áp dụng quy tắc về trọng âm và làm cho các từ trở nên tương đối rõ ràng và dễ hiểu, mặc dù có thể có một số từ không được nhấn trọng âm chính xác",
        8: "Học viên có khả năng nhấn trọng âm cho các từ khá chính xác. Họ thường áp dụng đúng quy tắc về trọng âm và làm cho các từ trở nên rõ ràng và dễ hiểu, mặc dù có thể gặp một số trường hợp không nhấn trọng âm hoàn toàn chính xác.",
        9: "Học viên có khả năng nhấn trọng âm cho các từ rất chính xác và tự nhiên. Họ hiểu và áp dụng quy tắc về trọng âm một cách linh hoạt và chính xác, khiến cho bài nói của họ trở nên dễ hiểu và tự nhiên.",
    }
};
export const INTONATION = {
    vName: 'Ngữ điệu',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường không có khả năng sử dụng ngữ điệu. Sự không sử dụng ngữ điệu gây ra sự mất tính rõ ràng và không hiểu rõ ý nghĩa cần truyền đạt",
        4: "THọc viên có khả năng sử dụng ngữ điệu rất hạn chế. Họ thường không thể thay đổi âm điệu một cách chính xác, làm giảm tính rõ ràng và cảm xúc của bài nói.",
        5: "Học viên có khả năng sử dụng ngữ điệu hạn chế. Họ có thể không thay đổi âm điệu một cách hiệu quả, dẫn đến sự mất đi tính rõ ràng và không đầy đủ của ý nghĩa cần truyền đạt",
        6: "Học viên có khả năng sử dụng ngữ điệu ở mức trung bình. Họ có thể thay đổi âm điệu nhưng thường không nhất quán hoặc không chính xác, làm giảm tính rõ ràng của bài nói.",
        7: "Học viên có khả năng sử dụng ngữ điệu tương đối linh hoạt. Họ có khả năng thay đổi âm điệu để diễn đạt ý nghĩa và cảm xúc, mặc dù có thể không nhất quán hoặc không sâu sắc",
        8: "Học viên có khả năng sử dụng ngữ điệu khá linh hoạt và tự nhiên. Họ thường sử dụng ngữ điệu một cách rõ ràng để thể hiện ý nghĩa và cảm xúc trong bài nói, mặc dù có thể không như linh hoạt và sâu sắc như ở band điểm 9",
        9: "Học viên có khả năng sử dụng ngữ điệu một cách rất linh hoạt và tự nhiên. Họ biết cách thay đổi âm điệu một cách chính xác để truyền đạt ý nghĩa, cảm xúc và sự nhấn mạnh trong bài nói của mình.",
    }
};
export const RHYTHM_SAID = {
    vName: 'Nhịp nói',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường không có khả năng duy trì nhịp điệu. Sự không duy trì được nhịp điệu chính xác gây ra sự mất đi tính liên tục và không dễ nghe",
        4: "Học viên có khả năng duy trì nhịp điệu rất hạn chế. Họ thường không duy trì được nhịp điệu chính xác, gây ra sự mất đi tính liên tục và dễ nghe của bài nói",
        5: "Học viên có khả năng duy trì nhịp điệu hạn chế. Họ có thể gặp khó khăn trong việc duy trì một nhịp điệu chính xác và thường bị gián đoạn trong luồng nói",
        6: "Học viên có khả năng duy trì nhịp điệu ở mức trung bình. Họ có thể duy trì nhịp điệu tương đối chính xác, nhưng thường không nhất quán hoặc có thể bị gián đoạn",
        7: "Học viên có khả năng duy trì nhịp điệu tương đối tự nhiên. Họ có thể duy trì nhịp điệu một cách đáng tin cậy, mặc dù có thể không nhất quán hoặc không linh hoạt hoàn toàn",
        8: "Học viên có khả năng duy trì nhịp điệu khá tự nhiên và linh hoạt. Họ thường duy trì một nhịp điệu chính xác, làm cho luồng nói trở nên dễ hiểu và truyền đạt ý nghĩa tốt",
        9: "Học viên có khả năng duy trì nhịp điệu một cách rất tự nhiên và linh hoạt. Họ giữ được nhịp điệu câu chữ một cách chính xác và thích hợp, tạo ra một luồng nói rõ ràng và dễ nghe.",
    }
};
//GRAMMATICAL RANGE AND ACCURACY
export const GRAMMAR_DIVERSITY ={
    vName: 'Độ đa dạng của ngữ pháp',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường không sử dụng các cấu trúc ngữ pháp nào ngoài câu đơn. Sự thiếu đa dạng và sử dụng cấu trúc ngữ pháp không đúng gây ra sự mất đi tính chính xác và rõ ràng của bài nói.",
        4: "Học viên sử dụng các cấu trúc ngữ pháp rất hạn chế. Họ thường chỉ sử dụng một số cấu trúc ngữ pháp cơ bản và có thể gặp nhiều lỗi khi sử dụng chúng",
        5: "Học viên sử dụng các cấu trúc ngữ pháp hạn chế. Họ có thể sử dụng một số cấu trúc ngữ pháp cơ bản, nhưng thường không linh hoạt và gặp khó khăn trong việc sử dụng một cách chính xác",
        6: "Học viên sử dụng các cấu trúc ngữ pháp ở mức trung bình. Họ có thể sử dụng một số cấu trúc ngữ pháp khá phổ biến một cách đúng đắn, nhưng thường không đa dạng và có thể gặp lặp lại",
        7: "Học viên sử dụng các cấu trúc ngữ pháp tương đối đa dạng. Họ có khả năng sử dụng một loạt các cấu trúc ngữ pháp, mặc dù có thể không nhất quán hoặc không sử dụng một cách linh hoạt",
        8: "Học viên sử dụng các cấu trúc ngữ pháp khá đa dạng và phong phú. Họ có khả năng sử dụng cấu trúc ngữ pháp phức tạp và linh hoạt, mặc dù có thể không như linh hoạt và sâu sắc như ở band điểm 9.",
        9: "Học viên sử dụng các cấu trúc ngữ pháp rất đa dạng và phong phú. Họ biết cách sử dụng cấu trúc ngữ pháp phức tạp và linh hoạt, đồng thời tránh được sự lặp lại và sử dụng một cách chính xác, tự nhiên.",
    }
};
export const ACCURACY_IN_USING_GRAMMAR = {
    vName: 'Độ chính xác trong sử dụng ngữ pháp',
    pointLadder: {
        1: "Không trả lời được câu hỏi nào",
        2: "Không trả lời được đa phần các câu hỏi ",
        3: "Học viên thường không có sự chính xác ngữ pháp ở hầu hết các trường hợp. Lỗi ngữ pháp xuất hiện thường xuyên và làm mất đi tính rõ ràng và đúng đắn của thông điệp.",
        4: "Học viên có sự chính xác ngữ pháp rất hạn chế. Họ thường gặp nhiều lỗi ngữ pháp cơ bản và có thể gây hiểu lầm trong thông điệp của họ.",
        5: "Học viên có sự chính xác ngữ pháp hạn chế. Họ có thể sử dụng một số cấu trúc ngữ pháp cơ bản, nhưng thường gặp lỗi nhiều và gây ra sự không chính xác trong diễn đạt.",
        6: "Học viên có sự chính xác ngữ pháp ở mức trung bình. Họ có thể sử dụng một số cấu trúc ngữ pháp cơ bản một cách đúng, nhưng thường gặp lỗi và có thể gây hiểu lầm.",
        7: "Học viên có sự chính xác ngữ pháp tương đối. Họ sử dụng ngữ pháp một cách chính xác trong phần lớn các trường hợp, nhưng có thể có một số lỗi không nghiêm trọng hoặc sử dụng ngữ pháp phức tạp không linh hoạt.",
        8: "Học viên có sự chính xác ngữ pháp khá cao. Họ thường sử dụng ngữ pháp một cách chính xác, nhưng có thể gặp một số lỗi nhỏ không ảnh hưởng nhiều đến sự hiểu rõ của thông điệp.",
        9: "Học viên có sự chính xác ngữ pháp rất cao. Họ sử dụng ngữ pháp một cách chính xác và linh hoạt trong nhiều tình huống khác nhau, hiểu rõ và sử dụng các cấu trúc ngữ pháp phức tạp một cách tự nhiên và không gặp lỗi nhiều.",
    }
};
//SkillWriting
export const CONTENT_COMMUNICATION_CIRCUIT = {
    vName: 'Thực hiện yêu cầu của đề bài',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường không hoàn thành nhiệm vụ. Bài viết của họ không đáp ứng yêu cầu của đề bài, không liên quan hoặc không hiểu rõ chủ đề, gây mất đi tính toàn vẹn và ý nghĩa của bài viết.",
        4: "Học viên hoàn thành nhiệm vụ rất hạn chế. Họ thường không trả lời đầy đủ yêu cầu cơ bản của đề bài, có thể đi lạc khỏi chủ đề hoặc không hiểu rõ yêu cầu.",
        5: "Học viên hoàn thành nhiệm vụ hạn chế. Họ có thể không trả lời đầy đủ các phần của đề bài, thiếu chi tiết và logic, làm giảm tính toàn diện và hiệu quả của bài viết.",
        6: "Học viên hoàn thành nhiệm vụ ở mức trung bình. Họ trả lời một phần của đề bài, nhưng thường thiếu chi tiết và có thể không rõ ràng hoặc hơi lệch hướng so với đề bài.",
        7: "Học viên hoàn thành nhiệm vụ tương đối tốt. Họ trả lời nhiều phần của đề bài, mặc dù có thể thiếu chi tiết hoặc không được tổ chức hoàn hảo, nhưng ý kiến vẫn rõ ràng và logic.",
        8: "Học viên hoàn thành nhiệm vụ tốt. Họ trả lời đầy đủ các phần của đề bài và cung cấp ý kiến rõ ràng và logic, mặc dù có thể không chi tiết và sâu sắc như ở band điểm 9.",
        9: "Học viên hoàn thành nhiệm vụ xuất sắc. Họ trả lời đầy đủ và chi tiết các phần của đề bài, không chỉ đáp ứng các yêu cầu cơ bản mà còn phát triển ý kiến một cách sâu sắc, minh bạch và logic.",
    }
};
export const ARGUMENT_AND_LOGICAL_CALCULATION_OF_THE_ARTICLE = {
    vName: 'Lập luận và tính logic của bài viết',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường không có lập luận. Lập luận của họ không rõ ràng, không logic và gây mất đi tính rõ ràng và đúng đắn của bài viết.",
        4: "Học viên có lập luận rất hạn chế. Họ thường không cung cấp lập luận logic, có thể làm mất đi tính toàn diện và ý nghĩa của bài viết.",
        5: "Học viên có lập luận hạn chế. Họ có thể cung cấp các ý kiến, nhưng thường không được tổ chức hoặc thiếu logic, làm giảm tính rõ ràng và hiệu quả của bài viết.",
        6: "Học viên có lập luận ở mức trung bình. Họ có thể cung cấp các ý kiến một cách tương đối logic, nhưng thường thiếu chi tiết và có thể không rõ ràng hoặc hơi lệch hướng so với chủ đề.",
        7: "Học viên có lập luận tương đối rõ ràng và có logic. Họ cung cấp các ý kiến một cách hợp lý và có cấu trúc logic, mặc dù có thể không nhất quán hoặc không được minh bạch hoàn toàn.",
        8: "Học viên có lập luận khá rõ ràng và có logic. Họ phát triển các ý kiến một cách rõ ràng và có cấu trúc logic, mặc dù không chi tiết và sâu sắc như ở band điểm 9.",
        9: "Học viên có lập luận rất rõ ràng và có logic. Họ phát triển các ý kiến một cách logic, minh bạch và liên kết, sử dụng các ví dụ và luận điểm phù hợp để minh chứng và bảo vệ quan điểm của mình.",
    }
};
// COHERENCE AND COHESION
export const COHERENCE_IN_CONTENT = {
    vName: 'Mạch lạc về mặt nội dung',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường không có sự mạch lạc về mặt nội dung. Sự thiếu liên kết và cấu trúc gây ra sự mất đi tính rõ ràng và ý nghĩa của bài viết.",
        4: "Học viên có sự mạch lạc về mặt nội dung rất hạn chế. Bài viết của họ thường không có cấu trúc hoặc không có sự liên kết giữa các ý, gây ra sự không rõ ràng và lộn xộn.",
        5: "Học viên có sự mạch lạc về mặt nội dung hạn chế. Bài viết của họ có thể không được tổ chức một cách rõ ràng và thiếu liên kết giữa các ý, làm giảm tính logic và rõ ràng của bài viết.",
        6: "Học viên có sự mạch lạc về mặt nội dung ở mức trung bình. Bài viết của họ có thể tổ chức một cách tương đối logic, nhưng thường thiếu sự kết nối rõ ràng giữa các ý. Sự chuyển đổi có thể không mượt mà.",
        7: "Học viên có sự mạch lạc về mặt nội dung tương đối tốt. Bài viết của họ có sự liên kết và cấu trúc, mặc dù có thể thiếu tính nhất quán hoặc không rõ ràng. Sự chuyển đổi giữa các ý vẫn diễn ra một cách hợp lý.",
        8: "Học viên có sự mạch lạc về mặt nội dung khá tốt. Họ tổ chức bài viết một cách logic và có cấu trúc, mặc dù không chi tiết và liên kết sâu sắc như ở band điểm 9. Sự kết nối giữa các ý vẫn rõ ràng và mượt mà.",
        9: "Học viên có sự mạch lạc về mặt nội dung xuất sắc. Bài viết của họ được tổ chức một cách logic, với mỗi đoạn văn chứa ý chính rõ ràng và được kết nối chặt chẽ với các ý khác trong bài. Sự chuyển đổi giữa các ý và đoạn văn diễn ra một cách mượt mà và tự nhiên.",
    }
};
export const FORMALLY_COHERENT = {
    vName: 'Mạch lạc về mặt hình thức',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường không sử dụng các từ nối và cấu trúc ngữ pháp cần thiết. Sự thiếu liên kết và cấu trúc không rõ ràng gây khó khăn trong việc hiểu được ý nghĩa của bài viết.",
        4: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp rất hạn chế. Bài viết thường không có cấu trúc hoặc không có sự liên kết giữa các ý, gây ra sự không rõ ràng và lộn xộn.",
        5: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp hạn chế. Bài viết có thể không được tổ chức một cách rõ ràng và thiếu liên kết giữa các ý, làm giảm tính logic và rõ ràng của bài viết.",
        6: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp ở mức trung bình. Bài viết có thể tổ chức một cách tương đối logic, nhưng thường thiếu sự kết nối rõ ràng giữa các ý. Sự chuyển đổi có thể không mượt mà.",
        7: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp một cách tương đối chính xác và linh hoạt. Bài viết có sự liên kết và cấu trúc khá hợp lý, mặc dù có thể thiếu tính nhất quán hoặc không rõ ràng ở một vài chỗ. Sự chuyển đổi giữa các ý vẫn diễn ra một cách hợp lý, tạo nên một bài viết có sự liên kết tốt.",
        8: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp khá chính xác và linh hoạt để liên kết các ý kiến, câu và đoạn văn. Mặc dù không chi tiết và liên kết sâu sắc như ở band điểm 9, nhưng sự chuyển đổi giữa các ý vẫn rõ ràng và mạch lạc.",
        9: "Học viên sử dụng các từ nối và cấu trúc ngữ pháp một cách rất chính xác và linh hoạt để nối các ý kiến, câu và đoạn văn một cách mượt mà và logic. Cách sắp xếp ý kiến giữa các câu và đoạn văn rất hợp lý và có sự liên kết chặt chẽ, tạo nên sự kết nối mạch lạc và logic.",
    }
};
// LEXICAL RESOURCE
export const VOCABULARY_DIVERSITY = {
    vName: 'Độ đa dạng của từ vựng',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường không sử dụng được đa dạng từ vựng và hầu như chỉ lặp lại các từ cơ bản. Bài viết không có sự đa dạng trong từ ngữ, gây ra sự nhàm chán và ý tưởng cũng trở nên không hấp dẫn.",
        4: "Học viên sử dụng từ vựng không đa dạng. Bài viết thường không có sự đa dạng trong từ ngữ, gây ra sự nhàm chán và giảm tính hấp dẫn của ý tưởng.",
        5: "Học viên sử dụng từ vựng kém đa dạng. Bài viết thường lặp lại từ ngữ và thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành, làm giảm tính phong phú và đa dạng của ý tưởng.",
        6: "Học viên có sử dụng từ vựng đa dạng ở mức trung bình. Bài viết có thể có sự lặp lại từ ngữ hoặc thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành, làm giảm tính phong phú và sâu sắc của ý tưởng.",
        7: "Học viên có sử dụng từ vựng tương đối đa dạng. Họ sử dụng một số từ vựng phong phú từ các lĩnh vực khác nhau, tuy nhiên, có thể thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành.",
        8: "Học viên có sử dụng từ vựng khá đa dạng. Họ sử dụng một loạt từ vựng rộng và linh hoạt từ các lĩnh vực khác nhau. Mặc dù không chi tiết và phong phú như ở band điểm 9, nhưng sự đa dạng vẫn góp phần tạo ra tính sâu sắc trong bài viết.",
        9: "Học viên có sử dụng một loạt các từ vựng rộng và phong phú. Họ biết cách sử dụng từ ngữ chính xác, linh hoạt và đa dạng từ các lĩnh vực khác nhau, bao gồm từ vựng thông thường và chuyên ngành. Sự đa dạng trong từ vựng tạo nên sự sâu sắc và mạch lạc trong ý kiến và ý tưởng.",
    }
};
export const VOCABULARY_PRECISION = {
    vName: 'Độ chính xác của từ vựng',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường sử dụng từ vựng không chính xác. Sự thiếu chính xác gây ra sự mất đi tính rõ ràng và ý nghĩa của bài viết.",
        4: "Học viên sử dụng từ vựng không chính xác. Bài viết thường có nhiều lỗi về từ ngữ, khiến ý nghĩa trở nên mơ hồ và không chính xác.",
        5: "Học viên sử dụng từ vựng khá thiếu chính xác. Bài viết thường có nhiều lỗi về từ ngữ, gây ra sự mất đi tính chính xác của ý tưởng.",
        6: "Học viên sử dụng từ vựng ở mức trung bình. Bài viết có thể có một số lỗi trong việc sử dụng từ ngữ hoặc không chính xác với ngữ cảnh, làm giảm tính chính xác và sâu sắc của ý tưởng.",
        7: "Học viên sử dụng từ vựng tương đối chính xác. Họ có khả năng sử dụng từ ngữ phù hợp, tuy nhiên, có thể xuất hiện một số lỗi nhỏ hoặc sự lặp lại từ ngữ không cần thiết trong bài viết.",
        8: "Học viên sử dụng từ vựng khá chính xác. Họ có khả năng sử dụng từ ngữ phù hợp và chính xác với ngữ cảnh, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại từ ngữ trong bài viết.",
        9: "Học viên sử dụng các từ vựng một cách chính xác và linh hoạt. Họ biết cách áp dụng từ ngữ đúng ngữ cảnh và không mắc lỗi về ý nghĩa hoặc cấu trúc câu. Việc sử dụng từ vựng chính xác góp phần tạo nên sự chính xác và sâu sắc trong ý kiến và ý tưởng.",
    }
};
// GRAMMATICAL RANGE AND ACCURACY
export const GRAMMAR_DIVERSITY_WRITING = {
    vName: 'Độ đa dạng của ngữ pháp',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên có khả năng sử dụng ngữ pháp rất hạn chế. Sự thiếu đa dạng gây ra sự mất đi tính rõ ràng và ý nghĩa của bài viết.",
        4: "Học viên sử dụng ngữ pháp không đa dạng. Bài viết thường không có sự đa dạng trong cấu trúc ngữ pháp, gây ra sự nhàm chán và giảm tính hấp dẫn của ý tưởng.",
        5: "Học viên sử dụng ngữ pháp khá kém đa dạng. Bài viết thường có nhiều lỗi về ngữ pháp, gây ra sự mất đi tính chính xác của ý tưởng.",
        6: "Học viên có sử dụng ngữ pháp ở mức trung bình. Bài viết có thể có một số lỗi trong việc sử dụng ngữ pháp hoặc không chính xác với ngữ cảnh, làm giảm tính chính xác và sâu sắc của ý tưởng.",
        7: "Học viên có sử dụng ngữ pháp tương đối đa dạng. Họ sử dụng một loạt các cấu trúc ngữ pháp từ cơ bản đến nâng cao, tuy nhiên, có thể xuất hiện một số lỗi nhỏ hoặc sự lặp lại cấu trúc không cần thiết trong bài viết.",
        8: "Học viên có sử dụng ngữ pháp khá đa dạng. Họ sử dụng một loạt các cấu trúc ngữ pháp phong phú và linh hoạt, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại cấu trúc trong bài viết.",
        9: "Học viên sử dụng một loạt các cấu trúc ngữ pháp linh hoạt và đa dạng. Họ biết cách sử dụng các cấu trúc ngữ pháp phức tạp một cách linh hoạt và tự nhiên, không mắc lỗi nghiêm trọng. Sự linh hoạt trong ngữ pháp góp phần tạo ra sự đa dạng và sâu sắc trong ý kiến và ý tưởng.",
    }
};
export const GRAMMATICAL_ACCURACY = {
    vName: 'Độ chính xác của ngữ pháp',
    pointLadder: {
        1: "Không viết được một chút nào",
        2: "Chưa hoàn thành phần lớn nội dung bài",
        3: "Học viên thường sử dụng ngữ pháp không chính xác. Sự thiếu chính xác gây ra sự mất đi tính rõ ràng và ý nghĩa của bài viết.",
        4: "Học viên sử dụng ngữ pháp không chính xác. Bài viết thường không có sự chính xác trong cấu trúc ngữ pháp, gây ra sự nhàm chán và giảm tính hấp dẫn của ý tưởng.",
        5: "Học viên có sử dụng ngữ pháp không thực sự chính xác. Bài viết thường có nhiều lỗi về ngữ pháp, gây ra sự mất đi tính chính xác của ý tưởng.",
        6: "Học viên có sử dụng ngữ pháp ở mức trung bình. Bài viết có thể có một số lỗi trong việc sử dụng ngữ pháp hoặc không chính xác với ngữ cảnh, làm giảm tính chính xác và sâu sắc của ý tưởng.",
        7: "Học viên có sử dụng ngữ pháp tương đối chính xác. Họ sử dụng một loạt các cấu trúc ngữ pháp từ cơ bản đến nâng cao, tuy nhiên, có thể xuất hiện một số lỗi nhỏ hoặc sự lặp lại cấu trúc không cần thiết trong bài viết.",
        8: "Học viên có sử dụng ngữ pháp khá chính xác. Họ sử dụng các cấu trúc ngữ pháp một cách chính xác, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại cấu trúc trong bài viết.",
        9: "Học viên sử dụng ngữ pháp một cách chính xác và linh hoạt. Họ hiểu rõ các cấu trúc ngữ pháp và sử dụng chúng một cách tự nhiên, không mắc lỗi ngữ pháp nghiêm trọng. Sự linh hoạt và chính xác trong ngữ pháp góp phần tạo ra sự rõ ràng và sâu sắc trong ý kiến và ý tưởng.",
    }
};

export const NOT_HAVE_ADMIN = 1;
export const STATUS_TO_DO = 0;
export const STATUS_SUCCESS = 1;
export const STATUS_CANCEL = 2;
export const STATUS_PENDING = 3; // Đang chờ xử lý
export const STATUS_FAlURE = 4; // Thất bại;
export const STATUS_OVER_LOADING = 5;
export const STATUS_CREATE = 6
export const STATUS_CHOOSE_OPTION = 7
export const STATUS_ONLY_GENERAL = 7;
export const STATUS_NOT_ACTIVE = 5;

export const Cambridge_Mentor_ID = 530;
export const PLAGIARISM_RATE = [
    {
        value: 1,
        label: 'Dưới 20%',
        problemSolution: 'Không trừ điểm',
        minusPoints: 0
    },
    {
        value: 2,
        label: 'Từ 20% tới 30%',
        problemSolution: 'Trừ 30% điểm',
        minusPoints: 0.3
    },
    {
        value: 3,
        label: 'Từ 30% tới 50%',
        problemSolution: 'Trừ 50% điểm',
        minusPoints: 0.5
    },
    {
        value: 4,
        label: 'Từ 50% trở lên',
        problemSolution: 'Trừ 100% điểm',
        minusPoints: 1
    },
]
